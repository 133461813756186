.header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 15rem;
    padding-left: 3rem;
    padding-right: 3rem;
    padding-bottom: 15rem;
}

.logo {
    height: 23rem;
    width: 23rem;
}

.prop {
    display: flex;
    justify-content: space-around;
    padding-bottom: 15rem;
}

.spacer {
    width: 8rem;
}

.causes {
    padding-bottom: 15rem;
}

.carousel {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.cause-block {
    position: relative;
    background: linear-gradient(90deg, rgba(0, 240, 255, 0.21) 0%, rgba(255, 0, 122, 0) 100%);
    border-style: solid;
    border-width: 5px;
    border-color: #00F0FF;
    border-radius: 41px;
    margin-top: 4rem;
    padding-top: 5rem;
    padding-bottom: 5rem;
    padding-left: 4rem;
    padding-right: 4rem;
    width: 39rem;
}

.cause-header {
    padding-bottom: 5rem;
}

.leaderboard {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

a.link {
    text-decoration: none;
    color: white;
    font-weight: 500;
    font-size: 1.75rem;
    line-height: 2.5rem;
    border: 2px solid #00F0FF;
    border-radius: 50px;
    padding: .3rem 1.5rem;
}

a.link:hover {
    font-weight: 700;
    cursor: pointer;
}

.NFT {
    width: 12vw;
}

.NFT-grid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}