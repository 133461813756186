.wallet-connect {
    display: flex;
    justify-content: right;
    padding-top: 2rem;
    padding-right: -1rem;
}

.wallet-connected, .wallet-not-connected {
    border-radius: 50px;
    border: 2px solid #00F0FF;
    padding: .2rem 1rem;
    color: white;
    font-weight: 500;
    font-size: 1rem;
    width: 11rem;
    align-items: center;
    text-align: center;
}

.wallet-connected {
    cursor: default;
    margin-bottom: 1rem;
}

.wallet-not-connected {
    background-color: transparent;
}

.header {
    display: flex;
    align-items: center;
}

h1 {
    font-size: 7rem;
    line-height: 7rem;
    text-align: center;
}

.buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.donate-button, .raise-button, .send-button, .copy-link-button {
    border-radius: 50px;
    border: 2px solid #00F0FF;
    padding: .3rem 1.5rem;
    color: white;
    font-weight: 500;
    font-size: 1rem;
    background-color: transparent;
}

.mint-button {
    border-radius: 50px;
    border: 2px solid #00F0FF;
    padding: .3rem 1.5rem;
    color: white;
    font-weight: 500;
    font-size: 1rem;
    margin-top: 2rem;
}

.send-button {
    display: inline;
    width: 140px;
    margin-top: 2rem;
    margin-bottom: 4rem;
}

.spacer {
    padding: 0.5rem;
}

.spacer-2 {
    padding: 0.25rem;
}

.amountInput {
    width: 40%;
    background-color: transparent;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 2px solid #00F0FF;
    border-radius: 0;
    color: white;

}

.form-control::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: white;
    opacity: 1; /* Firefox */
}

.form-label {
    color: white;
}


.amountInput:focus{
    border-bottom: 2px solid #00F0FF;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 2px solid #00F0FF;
    border-radius: 0;
    background-color: transparent;  
    color: white;

}

.close {
    color: #fff; 
    opacity: 1;
}

.modal-content {
    background-image: linear-gradient(to right, #0C3A5B , #0F0B30);
    border: 2px solid #00F0FF;
    border-radius: 40px;
    padding-bottom: 10px;
    padding-top: 10px;
    padding-left: 0px;
    padding-right: 0px;
}

.modalHeader {
    border: none;
    padding-bottom: 0;
    text-align: center;
}

.modalTitle {
    text-align: center;
}

.modalBody {
    padding-top: 0;
    padding-bottom: 4rem;
}

.modalFooter {
    border: none;
    text-align: center;
}

.btn-close {
    color: white;
}

.donateModal, .raiseModal, .mintModal {
    text-align: center;
    align-self: center;
}

.modalTitle {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.inviteLink {
    margin-top: 1rem;
    font-size: 1rem;
    border-radius: 50px;
    border: 2px solid #00F0FF;
    padding: .15rem 1.5rem;
    color: white;
    font-weight: 300;
    background-color: transparent;
}

.copy-link-button {
    font-size: 1rem;
}

.invite-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-top: 2rem;
}

.leaderboard {
    padding-bottom: 20rem;
    text-align: center;
}

.leaderboard-img {
    padding-top: 2rem;
    width: 60rem;
}

.table-titles {
    font-style: bold;
    display: flex;
    flex-direction: row;
}

.mint-div {
    background: linear-gradient(90deg, rgba(0, 240, 255, 0.21) 0%, rgba(255, 0, 122, 0) 100%);
    border-style: solid;
    border-width: 2px;
    border-color: #00F0FF;
    border-radius: 41px;
    margin-top: 4rem;
    padding-top: 5rem;
    padding-bottom: 5rem;
    padding-left: 4rem;
    padding-right: 4rem;
    width: 40rem;
}

.mint-div-head {
    display: flex;
    flex-direction: row;
}

.mint-div-img {
    height: 4rem;
}

.account-container {
    display: flex;
    flex-direction: column;
    justify-content: right;
    align-items: right;
    text-align: right;
}

.account-info {
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.4rem;
    padding-right: 1.5rem;
    padding-bottom: 0rem;
    background: linear-gradient(90deg, #00F0FF 10%, #5773FF 40%, #FF007A 70%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text; 
}

.user-NFT {
    padding-bottom: 15rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.nft-desc {
    background: linear-gradient(90deg, #00F0FF 10%, #5773FF 40%, #FF007A 70%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text; 
}

.opensea {
    text-decoration: underline;
    color: white;
    font-size: 1.3rem;
}

.promptUpdate, .promptMint {
    display: none;
}

.gallery-header {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.NFT {
    width: 12vw;
}

.NFT-grid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-bottom: 15rem;
    padding-top: 3rem;
}

.userNFT {
    display: none;
    width: 15rem;
}